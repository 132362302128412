import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PageHero from "../components/pagehero"
import Footer from "../components/footer"
const NotFoundPage = () => (
  <div>
    <PageHero
    heading="NOT FOUND"
    description="You just hit a route that doesn&#39;t exist... sorry about that."
    pageTitle="404"
    secondaryButtonTitle= "Go back home"
    secondaryButtonUrl= "/"
    imgSrc="404page.jpg"
    ></PageHero>
    <SEO title="404: Not found" />
    <Footer></Footer>
  </div>
)

export default NotFoundPage
